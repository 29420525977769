import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Text,
    Image,
    Button,
    Flex,
    Stack,
    Spacer,
    Divider,
    Box,
    Center,
    Spinner,
    HStack,
    Select,
    Input,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { ApiKey, _AMOUNT_TYPE, _WEB_VERSION, _WALLET_ACTIONS, TransactionType, STATUS_TYPE, Status, ScreenWidth, WithdrawAction } from "../../../common/constant";
import { numberWithCurrencyFormat, isObjectEmpty, stringIsNullOrEmpty, getValidationMessage, checkBrowser } from "../../../common/util";
import { Pages } from "../../../routes/page";
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import CurrencyInput from "react-currency-input-field";
import PasswordModal from "../../../component/password-modal";
import CustomPicker from "../../../component/custom-picker";
import { setBusy, setIdle } from "../../../application/action/app_action";
import { isMobile } from "react-device-detect";
import Header from "../../non-auth-pages/header";
import { ChevronLeftIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { getMemberDetails } from "../../../application/action/auth_action";
import axios from 'axios';
import Footer from "../../non-auth-pages/footer";
import { ConfigEnum } from "../../../common/config";


/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    var _router = useRouter();
    var _location = useLocation();
    var _navigate = useNavigate();
    var _dispatch = useDispatch();
    const _PARAMS = _location?.state;
    const _userData = useSelector((state) => state.authState.userData);
    let userVendorList = _PARAMS?.userVendorList;
    const selectedPlayer = _PARAMS?.selectedPlayer;
    const [vendorList, setVendorList] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [playerScore, setPlayerScore] = useState(0);
    const _CONVERT_TO_CREDIT = 'CONVERT_TO_CREDIT';
    const [playerDetail, setPlayerDetail] = useState(_PARAMS?.selectedPlayer);
    const selectedVendor = _PARAMS?.selectedVendor;
    const cancelTokenSource = axios.CancelToken.source();
    const [convertState, setConvertState] = useState(_CONVERT_TO_CREDIT);
    const [resetAmountBit, setResetAmountBit] = useState(true);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [vendorPickerVisible, setVendorPickerVisible] = useState(false);
    const [playerPickerVisible, setPlayerPickerVisible] = useState(false);
    const watchSelectedVendor = watch('selectedVendor');
    const watchSelectedPlayer = watch('selectedPlayerId');
    const [firstInit, setFirstInit] = useState(true);
    const [bankPickerVisible, setBankPickerVisible] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [content, setContent] = useState();
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [withdrawAmountConstraints, setWithdrawAmountConstraints] = useState({
        min: 0,
        max: 0
    });
    const [bankDetails, setBankDetails] = useState({
        bankId: '',
        bankAccountNumber: '',
        bankAccountHolderName: ''
    });
    const [authorizationModal, setAuthorizationModal] = useState({
        password: '',
        visible: false
    });
    const MODES = [
        {
            text: `${t('SUBMIT_REQUEST')}`,
            subText: `${t('ONLINE_TRANSFER')}`,
            value: WithdrawAction._ONLINE_WITHDRAWAL
        },
        {
            text: `${t('QR_CODE')}`,
            subText: `${t('CASH_PAYMENTS')}`,
            value: WithdrawAction._SUBMIT_REQUEST
        }
    ];
    const [selectedMode, setSelectedMode] = useState(MODES[0].value);

    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            // _router.back()
        }
        else {
            refreshCreditScore(false);
            setValue('amount', numberWithCurrencyFormat(selectedPlayer.score));
        }

        // Component unmount
        return () => {
            // Cancel ongoing request
            cancelTokenSource.cancel();
        }
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getPlayerDetail() {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${playerDetail?.username ?? watchSelectedPlayer?.username}`,
                {
                    cancelToken: cancelTokenSource.token,
                    headers: { "skipLoading": true }
                }
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            const {
                score,
                disable,
                isSameRegion
            } = responseJson[ApiKey._API_DATA_KEY][0];

            setPlayerDetail({
                ...playerDetail,
                isSameRegion,
                score,
                status: disable ? Status._DISABLED : Status._ENABLED
            });

            if (convertState === _CONVERT_TO_CREDIT) {
                setValue('amount', numberWithCurrencyFormat(score ?? 0).toString());

                if (errors?.amount && score > 0) {
                    clearErrors('amount');
                }
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function refreshCreditScore(readPlayerScore, isResetRequired = false) {
        setIsLoadingDetail(true);
        await _dispatch(getMemberDetails()); // Refresh wallet credit

        // Get player score
        if (!selectedVendor?.enableManualReadScore || readPlayerScore) {
            await getPlayerDetail();
        }

        if (isResetRequired) {
            setResetAmountBit(!resetAmountBit);
        }

        setIsLoadingDetail(false);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _dispatch(setBusy());
        (async () => {
            try {
                getBankList();

                if (!userVendorList) {
                    var responseJson = await ApiEngine.get(
                        Routes._GET_USERINFO_URL +
                        '?id=' +
                        _userData.guid +
                        '&versionCode=' +
                        _WEB_VERSION
                    );

                    if (
                        responseJson[ApiKey._API_SUCCESS_KEY] &&
                        responseJson[ApiKey._API_DATA_KEY].vendors_player
                            .length > 0
                    ) {
                        userVendorList = responseJson[
                            ApiKey._API_DATA_KEY
                        ].vendors_player
                            .filter((item) => item.players.length > 0)
                            .map((item) => ({
                                ...item,
                                label: item.vendor_name,
                                value: item.vendor_id,
                                id: parseInt(item.vendor_id),
                                players: item.players.map((itm) => ({
                                    ...itm,
                                    label: itm.vendor_username,
                                    value: itm.player_id,
                                    username: itm.vendor_username
                                }))
                            }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }
                else {
                    userVendorList = [...userVendorList]
                        .filter((item) => item.players.length > 0)
                        .map((item) => ({
                            ...item,
                            label: item.gameName,
                            value: item.vendor_id,
                            players: item.players.map((itm) => ({
                                ...itm,
                                label: itm.username,
                                value: itm.id
                            }))
                        }));
                }

                let responsePlayerList = [];
                setVendorList(userVendorList);

                if (selectedPlayer) {
                    responsePlayerList = userVendorList.find(
                        (item) => item.id == selectedPlayer.vendorId
                    ).players;
                    setPlayerList(responsePlayerList);
                    setValue(
                        'selectedVendor',
                        userVendorList.find(
                            (item) => item.id == selectedPlayer.vendorId
                        )
                    );
                    setValue(
                        'selectedPlayerId',
                        responsePlayerList.find(
                            (item) => item.id == selectedPlayer.id
                        )
                    );
                }
                else {
                    responsePlayerList = userVendorList[0].players;
                    setPlayerList(responsePlayerList);
                    setValue('selectedVendor', userVendorList[0]);
                    setValue('selectedPlayerId', responsePlayerList[0]);
                }
            } catch (err) {
                _dispatch(showCustomDialog({ success: false, content: err }));
            } finally {
                _dispatch(setIdle());
            }
        })();
    }, [selectedMode]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!firstInit && watchSelectedVendor) {
            let newPlayerList = watchSelectedVendor.players;
            setValue('selectedPlayerId', newPlayerList[0]);
            setPlayerScore(newPlayerList[0].wallet_balance);
            setPlayerList(newPlayerList);
        } else {
            setFirstInit(false);
        }
    }, [watchSelectedVendor]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        getPlayerScore();
    }, [watchSelectedPlayer]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getBankList = async () => {
        try {
            _dispatch(setBusy());
            let responseJson = await ApiEngine.get(Routes._BANK_INFO, { headers: { "skipLoading": true } });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setWithdrawAmountConstraints({
                min: responseJson[ApiKey._API_DATA_KEY]?.minThreshold,
                max: responseJson[ApiKey._API_DATA_KEY]?.maxThreshold
            });

            setValue(
                'amount',
                responseJson[ApiKey._API_DATA_KEY]?.minThreshold
            );

            let userBankResponseJson = await ApiEngine.get(`${Routes._GET_USER_BANK_ACCOUNTS}?userId=${_userData.guid}`, { headers: { "skipLoading": true } });

            if (!userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
                throw userBankResponseJson[ApiKey._API_MESSAGE_KEY];
            }

            let userBanks = userBankResponseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

            if (userBanks.length > 0) {
                setValue('bankAccountHolderName', userBanks[0]['bankAccountHolderName']);
                setBankList(userBanks.map(item => ({
                    label: item?.bankName,
                    value: item?.id,
                    imagePath: item?.bankLogo,
                    accountNo: item?.bankAccountNumber,
                    bankId: item?.bankId
                })));
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getPlayerScore() {
        if (watchSelectedPlayer) {
            var responseJson = await ApiEngine.get(
                `${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${watchSelectedPlayer.username}`
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setPlayerScore(
                    responseJson[ApiKey._API_DATA_KEY][0].wallet_balance
                );
            }
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        setAuthorizationModal({ ...authorizationModal, visible: true, data: data });
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const authorizationSuccess = async (authorization) => {
        try {
            if (selectedMode == WithdrawAction._ONLINE_WITHDRAWAL) {
                let raw = JSON.stringify({
                    amount: watch('amount'),
                    bankAccountId: watch('bankId')?.value,
                    password: authorization.password
                });

                let responseJson = await ApiEngine.post(
                    Routes._ONLINE_WITHDRAWAL,
                    raw,
                    {
                        ...ApplicationJsonHeaderConfig
                    }
                );

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                _router.navigate(Pages._TRANSACTION_HISTORY, { type: _WALLET_ACTIONS.WITHDRAWAL_REQUEST }, true);
                _router.navigate(Pages._TRANSACTION_HISTORY_DETAIL, {
                    type: TransactionType._WITHDRAW_CREDIT,
                    status: STATUS_TYPE._PENDING_APPROVAL,
                    transactionId: responseJson[ApiKey._API_DATA_KEY]
                });
            } else {
                _dispatch(
                    showCustomDialog({
                        success: true,
                        title: t('WITHDRAW_CONFIRMATION'),
                        content: t('CONFIRM_WITHDRAW_GAME_SCORE', {
                            amount: authorization.data.amount
                        }),
                        onConfirm: () => {
                            createWithdrawTransaction(authorization.data);
                        },
                        confirmTxt: t('CONFIRM'),
                        onCancel: () => { },
                        horizontalBtn: false
                    })
                );
            }
        }
        catch (err) {
            setTimeout(() => {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err,
                        onConfirm: () => { }
                    })
                );
            }, 300);
        }
    }

    const createWithdrawTransaction = async (data) => {
        let amount = data.amount * -1;

        let url = Routes._CREATE_TRANSACTION_REQUEST + `?amount=${amount}`;

        let responseJson = await ApiEngine.post(url);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._QR_GENERATION, {
                title: 'WITHDRAW_GAME_SCORE',
                amount: data.amount,
                requestCode: responseJson['data']
            });
        } else {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: responseJson[ApiKey._API_MESSAGE_KEY]
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('selectedVendor')) && !stringIsNullOrEmpty(watch('selectedPlayerId')) && !stringIsNullOrEmpty(watch('amount'))
            && !stringIsNullOrEmpty(watch('bankId')));
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'WITHDRAW_GAME_SCORE'} bgColor="linear-gradient(#a94ff1,#9e32dd)">
                    <ConversionTopBar amount={playerScore} onRefresh={getPlayerScore} />
                </GeneralTopBar>
                <Stack className="header-margin-top">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Button variant={'link'} w='full' onClick={() => setVendorPickerVisible(!vendorPickerVisible)}>
                            <Controller
                                control={control}
                                name="selectedVendor"
                                defaultValue={''}
                                render={({ value, name }) => (
                                    <CustomInput
                                        isReadOnly
                                        id={name}
                                        value={value?.label}
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        border='2px solid #e2a570 !important'
                                        borderRadius='10px !important'
                                        label={'1. ' + t('SELECT_VENDOR')}
                                        placeholder={t('SELECT_BANK')}
                                        errors={errors}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Button>
                        <Button variant={'link'} w='full' onClick={() => { playerList.length > 0 && setPlayerPickerVisible(!playerPickerVisible); }}>
                            <Controller
                                control={control}
                                name="selectedPlayerId"
                                defaultValue={''}
                                render={({ value, name }) => (
                                    <CustomInput
                                        isReadOnly
                                        id={name}
                                        value={value?.label}
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        border='2px solid #e2a570 !important'
                                        borderRadius='10px !important'
                                        label={'2. ' + t('SELECT_PLAYER_ID')}
                                        placeholder={t('SELECT_BANK')}
                                        errors={errors}
                                        subLabel={
                                            'RM ' +
                                            numberWithCurrencyFormat(playerScore)
                                        }
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Button>
                        <Flex>
                            <Text fontSize={'sm'}>{'3. ' + t('ENTER_AMOUNT')}</Text>
                            <Spacer />
                            <Text fontStyle={'italic'}>{t('MIN_AMOUNT_RM') + withdrawAmountConstraints?.min}</Text>
                        </Flex>
                        <Stack mb='25px'>
                            <Box w='full' background={'linear-gradient(#5a0089,#7c15bd)'} borderRadius={10} borderColor='#e2a570' borderWidth={2} mt={2}>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={0}
                                    render={({ onChange, value, name }) => (
                                        <Stack direction='row' paddingInline={3}>
                                            <CurrencyInput
                                                style={{
                                                    textAlign: 'start',
                                                    backgroundColor: 'transparent',
                                                    fontWeight: '500',
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    color: 'white',
                                                    outline: 0
                                                }}
                                                id={name}
                                                name={name}
                                                value={value}
                                                placeholder="0.00"
                                                decimalScale={2}
                                                autoComplete={false}
                                                onFocus={(e) => e.target.select()}
                                                onValueChange={(value, name) => {
                                                    onChange(value ?? 0)
                                                }}
                                            />
                                            <Divider className="divider-vertical" orientation='vertical' h={'2.6rem !important'} />
                                            <Button variant='link'
                                                onClick={() => {
                                                    setValue('amount', playerScore);
                                                }}>
                                                <Text fontSize={'sm'}>{t('MAX')}</Text>
                                            </Button>
                                        </Stack>
                                    )}
                                    rules={{
                                        required: true,
                                        min: withdrawAmountConstraints?.min,
                                        max: withdrawAmountConstraints?.max
                                    }}
                                />
                            </Box>
                            {
                                errors && errors['amount'] &&
                                <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                            }
                        </Stack>
                        <Text mb={2} fontSize={'sm'}>{`4. ${t('SELECT_WITHDRAW_MODE')}`}</Text>
                        <HStack w={'100%'} mb={3}>
                            {MODES.map((value, index) => {
                                return (
                                    <Stack w={'100%'}>
                                        <Button fontSize={'md'} onClick={() => { setSelectedMode(value.value) }} paddingBlock={6} borderRadius={20} color={'white'} border={selectedMode == value.value ? '2px solid #f8c763' : 0} bg={selectedMode == value.value ? 'linear-gradient(#ac4cfe,#7416ab)' : 'linear-gradient(#5a0089,#7c15bd)'}>{value.text}</Button>
                                        <Text fontSize={'sm'} color={'#e4b6ff'} textAlign={'center'}>({value.subText})</Text>
                                    </Stack>
                                )
                            })}
                        </HStack>
                        {
                            selectedMode == WithdrawAction._ONLINE_WITHDRAWAL &&
                            <>
                                <Controller
                                    control={control}
                                    name="bankId"
                                    defaultValue={bankDetails?.bankId}
                                    render={({ value, name }) => (
                                        <>
                                            <Flex mb={-5}>
                                                <Text fontSize={'sm'}>{`5. ${t('BANK_NAME')}`}</Text>
                                                <Spacer />
                                                <Button variant='link' onClick={() => { _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: watch('bankAccountHolderName'), isNewUser: false }) }}>
                                                    <Text as='i' decoration='underline' color={'#f8c763'}>{t('ADD_BANK_ACCOUNT')}</Text>
                                                </Button>
                                            </Flex>
                                            <Button variant={'link'} w='full' onClick={() => {
                                                if (bankList.length > 0) {
                                                    setBankPickerVisible(!bankPickerVisible)
                                                }
                                                else {
                                                    _dispatch(showCustomDialog({
                                                        isPlainTheme: true,
                                                        title: 'ADD_BANK',
                                                        customContent: (
                                                            <Text>{t('IT_APPEARS_THAT_YOU_HAVENT_ADDED_A_BANK_FOR_WITHDRAWAL')}</Text>
                                                        ),
                                                        confirmTxt: 'BRING_ME_THERE',
                                                        onConfirm: () => {
                                                            _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: '', isNewUser: false })
                                                        },
                                                        onCancel: () => { }
                                                    }));
                                                }
                                            }}>
                                                <CustomInput
                                                    isReadOnly
                                                    id={name}
                                                    value={value?.label}
                                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                                    border='2px solid #e2a570 !important'
                                                    borderRadius='10px !important'
                                                    placeholder={t('SELECT_BANK')}
                                                    errors={errors}
                                                    icon={
                                                        <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                                    }
                                                />
                                            </Button>
                                        </>
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    control={control}
                                    name="bankAccountHolderName"
                                    defaultValue={bankDetails?.bankAccountHolderName}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            readOnly
                                            id={name}
                                            value={value}
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #e2a570 !important'
                                            borderRadius='10px !important'
                                            label={`4. ${t('BENEFICIARY_NAME')}`} />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    control={control}
                                    name="bankAccountNumber"
                                    defaultValue={
                                        bankDetails?.bankAccountNumber
                                    }
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            readOnly
                                            id={name}
                                            value={value}
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #e2a570 !important'
                                            borderRadius='10px !important'
                                            label={`6. ${t('ACCOUNT_NUMBER')}`} />
                                    )}
                                    rules={{ required: true }}
                                />
                            </>
                        }
                        <Button className="grey-gradient-button" mt={8} type='submit'>
                            {t('WITHDRAW')}
                        </Button>
                    </form>
                </Stack>
                <CustomPicker
                    isVisible={vendorPickerVisible}
                    title={'SELECT_VENDOR'}
                    options={vendorList}
                    selectedOption={watch('selectedVendor')}
                    onSelect={(isSelected = true, value) => {
                        if (isSelected) {
                            setValue('amount', '0.00');
                            setValue('selectedVendor', value);
                        }

                        setVendorPickerVisible(!vendorPickerVisible);
                    }}
                />
                <CustomPicker
                    isVisible={playerPickerVisible}
                    title={'SELECT_PLAYER_ID'}
                    options={playerList}
                    selectedOption={watch('selectedPlayerId')}
                    onSelect={(isSelected = true, value) => {
                        if (isSelected) {
                            setValue('amount', '0.00');
                            setValue('selectedPlayerId', value);
                        }

                        setPlayerPickerVisible(!playerPickerVisible);
                    }}
                />
                <CustomPicker
                    showWhiteBg
                    isVisible={bankPickerVisible}
                    title={'SELECT_BANK'}
                    options={bankList}
                    selectedOption={watch('bankId')}
                    onSelect={(isSelected = true, value) => {
                        if (isSelected) {
                            setValue('bankId', value);
                            setValue('bankAccountNumber', value.accountNo)
                        }
                        setBankPickerVisible(!bankPickerVisible);
                    }}
                />
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            authorizationSuccess(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    } else {
        return (
            <>
                <Header />
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('GAME_SCORE_WITHDRAW')}</Text>
                        </div>
                        <Stack>
                            <Flex gap={4} mb={5}>
                                <Flex align={'center'}>
                                    <Text fontSize={'sm'} mr={5}>{t('ECREDIT')}</Text>
                                    <Box
                                        position={'relative'}
                                        paddingInline={6}
                                        paddingBlock={.5}
                                        borderColor={'#f8c763'}
                                        borderWidth={'1px'}
                                        borderRadius={'15px'}
                                        bg={'linear-gradient(#3f0864,#5a1487)'}>
                                        <Image
                                            style={{
                                                position: 'absolute',
                                                left: '-18px',
                                                top: '-5px'
                                            }}
                                            boxSize={'35px'}
                                            src={require('../../../assets/images/topbar-icon-credit.png')} />
                                        <Text fontSize={'sm'}>{numberWithCurrencyFormat(_userData.credit ?? 0)}</Text>
                                    </Box>
                                </Flex>
                                <Flex align={'center'}>
                                    <Text fontSize={'sm'} mr={5}>{t('EPOINT')}</Text>
                                    <Box
                                        position={'relative'}
                                        paddingInline={6}
                                        paddingBlock={.5}
                                        borderColor={'#f8c763'}
                                        borderWidth={'1px'}
                                        borderRadius={'15px'}
                                        bg={'linear-gradient(#3f0864,#5a1487)'}>
                                        <Image
                                            style={{
                                                position: 'absolute',
                                                left: '-18px',
                                                top: '-5px'
                                            }}
                                            boxSize={'35px'}
                                            src={require('../../../assets/images/topbar-icon-score.png')} />
                                        <Text fontSize={'sm'}>{numberWithCurrencyFormat(playerScore ?? 0)}</Text>
                                    </Box>
                                </Flex>
                                {
                                    isLoadingDetail ?
                                        <Spinner color='white' /> :
                                        <Button variant='link' onClick={() => { refreshCreditScore(true); }}>
                                            <Image
                                                boxSize={'30px'}
                                                src={require('../../../assets/images/svg/topbar-icon-refresh.svg').default} />
                                        </Button>
                                }
                            </Flex>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <HStack alignItems={'flex-start'} spacing={'20'} mb={7}>
                                    <div style={{ minWidth: '330px' }}>
                                        <Text fontSize={'sm'} mb={3}>1. {t('SELECT_VENDOR')}</Text>
                                        <Controller
                                            name="selectedVendor"
                                            control={control}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <Select {...field} color={'white'} bg='linear-gradient(#5a0089,#7c15bd) !important' mb={7} height={50} fontSize={'sm'} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                    fontWeight={'bold'} borderRadius={15} value={watchSelectedVendor ? watchSelectedVendor.id : selectedVendor}
                                                    onChange={(e) => {
                                                        setValue('amount', '0.00');
                                                        setValue('selectedVendor', vendorList.find((item) => item.id == e.target.value));
                                                    }}
                                                    icon={<TriangleDownIcon fontSize={13} />}>
                                                    {vendorList.map((option) => (
                                                        <option key={option.value} value={option.value} style={{ background: '#6f0daa' }}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                        <Text fontSize={'sm'} mb={3} >2. {t('SELECT_PLAYER_ID')}</Text>
                                        <Controller
                                            control={control}
                                            name="selectedPlayerId"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <Select {...field} color={'white'} bg='linear-gradient(#5a0089,#7c15bd) !important' mb={7} height={50} fontSize={'sm'} errors={errors} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                    fontWeight={'bold'} borderRadius={15} value={watchSelectedPlayer ? watchSelectedPlayer.id : selectedPlayer}
                                                    onChange={(e) => {
                                                        setValue('amount', '0.00');
                                                        setValue('selectedPlayerId', playerList.find((item) => item.value == e.target.value));
                                                    }}
                                                    icon={<TriangleDownIcon fontSize={13} />}>
                                                    {playerList.map((option) => (
                                                        <option key={option.value} value={option.value} style={{ background: "#6f0daa" }}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                        <HStack mb={3}>
                                            <Text fontSize={'sm'}>{'3. ' + t('ENTER_AMOUNT')}</Text>
                                            <Spacer />
                                            <Text fontStyle={'italic'} fontSize={'sm'}>{t('MIN_AMOUNT_RM') + withdrawAmountConstraints?.min}</Text>
                                        </HStack>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue={0}
                                            render={({ onChange, value, name }) => (
                                                <Stack direction='row' paddingInline={4} bg='linear-gradient(#5a0089,#7c15bd) !important' borderRadius={15} height={50} mb={1} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}>
                                                    <CurrencyInput
                                                        style={{
                                                            textAlign: 'start',
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            fontSize: '0.875rem',
                                                            width: '100%',
                                                            outline: 0,
                                                        }}
                                                        id={name}
                                                        name={name}
                                                        value={value}
                                                        placeholder="0.00"
                                                        decimalScale={2}
                                                        autoComplete={false}
                                                        onFocus={(e) => e.target.select()}
                                                        onValueChange={(value, name) => {
                                                            let newValue = value

                                                            if (newValue > selectedPlayer.score) {
                                                                newValue = selectedPlayer.score;
                                                            }

                                                            onChange(newValue);
                                                            // onChange(value ?? 0) here
                                                        }}
                                                    />
                                                    <Divider orientation='vertical' borderColor={'white'} h={'35px'} margin={'auto'} />
                                                    <Button variant='link'
                                                        onClick={() => {
                                                            setValue('amount', playerScore);
                                                        }}>
                                                        <Text fontSize={'sm'}>{t('MAX')}</Text>
                                                    </Button>
                                                </Stack>
                                            )}
                                            rules={{
                                                required: true,
                                                min: withdrawAmountConstraints?.min,
                                                max: withdrawAmountConstraints?.max
                                            }}
                                        />
                                        {
                                            errors && errors['amount'] && <>
                                                <Text color={'red'} fontSize={'sm'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                            </>
                                        }
                                    </div>
                                    <div style={{ minWidth: '330px' }}>
                                        <Text mb={2}>{`3. ${t('SELECT_WITHDRAW_MODE')}`}</Text>
                                        <HStack w={'100%'} mb={3}>
                                            {MODES.map((value, index) => {
                                                return (
                                                    <Stack w={'100%'}>
                                                        <Button fontSize={'md'} onClick={() => { setSelectedMode(value.value) }} paddingBlock={6} borderRadius={20} color={'white'} border={selectedMode == value.value ? '2px solid #f8c763' : 0} bg={selectedMode == value.value ? 'linear-gradient(#ac4cfe,#7416ab)' : 'linear-gradient(#5a0089,#7c15bd)'}>{value.text}</Button>
                                                        <Text fontSize={'sm'} color={'#e4b6ff'} textAlign={'center'}>({value.subText})</Text>
                                                    </Stack>
                                                )
                                            })}
                                        </HStack>
                                        {
                                            selectedMode == WithdrawAction._ONLINE_WITHDRAWAL &&
                                            <>
                                                <HStack mb={3}>
                                                    <Text fontSize={'sm'}>{'5. ' + t('BANK_NAME')}</Text>
                                                    <Spacer />
                                                    <Button variant='link' onClick={() => { _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: watch('bankAccountHolderName'), isNewUser: false }) }}>
                                                        <Text fontSize={'sm'} as='i' color={'#f8c763'} decoration='underline'>{t('ADD_BANK_ACCOUNT')}</Text>
                                                    </Button>
                                                </HStack>
                                                <Controller
                                                    control={control}
                                                    name="bankId"
                                                    defaultValue={bankDetails?.bankId}
                                                    render={({ field }) => (
                                                        <>
                                                            {/* <Button variant={'link'} w='full' onClick={() => {
                                                        if (bankList.length > 0) {
                                                            setBankPickerVisible(!bankPickerVisible)
                                                        }
                                                        else {
                                                            _dispatch(showCustomDialog({
                                                                isPlainTheme: true,
                                                                title: 'ADD_BANK',
                                                                customContent: (
                                                                    <Text>{t('IT_APPEARS_THAT_YOU_HAVENT_ADDED_A_BANK_FOR_WITHDRAWAL')}</Text>
                                                                ),
                                                                confirmTxt: 'BRING_ME_THERE',
                                                                onConfirm: () => {
                                                                    _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: '', isNewUser: false })
                                                                },
                                                                onCancel: () => { }
                                                            }));
                                                        }
                                                    }}> */}
                                                            <Select {...field} color={'white'} bg='linear-gradient(#5a0089,#7c15bd) !important' mb={errors["bankId"] ? 1 : 7} height={50} fontSize={'sm'} errors={errors}
                                                                fontWeight={'bold'} borderRadius={15} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                                onChange={(e) => {
                                                                    setValue('bankId', bankList.find((item) => item.value == e.target.value));
                                                                    setValue('bankAccountNumber', bankList.find((item) => item.value == e.target.value).accountNo);
                                                                }}
                                                                icon={<TriangleDownIcon fontSize={13} />}>
                                                                <option disabled selected style={{ background: "#6f0daa" }}>{t('SELECT_BANK')}</option>
                                                                {bankList.map((option) => (
                                                                    <option key={option.value} value={option.value} style={{ background: "#6f0daa" }}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                    rules={{ required: true }}
                                                />
                                                {errors && errors["bankId"] &&
                                                    <Text color={'red'} fontSize={'sm'} mb={1} textAlign={'left'}>{t(getValidationMessage(errors["bankId"]))}</Text>}
                                                <Text fontSize={'sm'} mb={3}>{'4. ' + t('BENEFICIARY_NAME')}</Text>
                                                <Controller
                                                    control={control}
                                                    name="bankAccountHolderName"
                                                    defaultValue={bankDetails?.bankAccountHolderName}
                                                    value={watch('bankAccountHolderName')}
                                                    render={({ onChange, value, name }) => (
                                                        <Input
                                                            value={value}
                                                            id={name}
                                                            color={'white'}
                                                            height={50}
                                                            borderRadius={15}
                                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                                            border={'none'}
                                                            fontWeight={'bold'}
                                                            mb={7}
                                                            readOnly />
                                                    )}
                                                    rules={{ required: true }}
                                                />
                                                <Text fontSize={'sm'} mb={3}>{'6. ' + t('ACCOUNT_NUMBER')}</Text>
                                                <Controller
                                                    control={control}
                                                    name="bankAccountNumber"
                                                    defaultValue={bankDetails?.bankAccountNumber}
                                                    render={({ onChange, value, name }) => (
                                                        <Input
                                                            value={value}
                                                            id={name}
                                                            color={'white'}
                                                            height={50}
                                                            borderRadius={15}
                                                            bg='linear-gradient(#5a0089,#7c15bd) !important'
                                                            border={'none'}
                                                            fontWeight={'bold'}
                                                            mb={7}
                                                            readOnly />
                                                    )}
                                                    rules={{ required: true }}
                                                />
                                            </>
                                        }
                                    </div>
                                </HStack>
                                <div style={{ textAlign: "center" }}>
                                    <Button type='submit' color={'white'} height={50} padding={'20px 140px'} borderRadius={15} mb={2} bg={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : 'linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%)'}>
                                        {t('WITHDRAW')}
                                    </Button>
                                </div>
                            </form>
                        </Stack>
                        <PasswordModal
                            authorization={authorizationModal}
                            onPasswordHandler={(isDone = false, authorization) => {
                                if (isDone) {
                                    authorizationSuccess(authorization);
                                }

                                setAuthorizationModal({ ...authorization, visible: false });
                            }} />
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}