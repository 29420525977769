import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Text, Flex, Button, Image, Stack, Spacer, Divider, Box, AbsoluteCenter, IconButton, Center } from "@chakra-ui/react";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { showCustomDialog } from "../../../application/action/app_action";
import { stringIsNullOrEmpty, share, checkBrowser } from "../../../common/util";
import { ApiKey, _EVENT_STATUS, ScreenWidth } from "../../../common/constant";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const [customerService, setCustomerSerivce] = useState();
    const [customerServiceAvatar, setCustomerServiceAvatar] = useState();
    const [vendorAds, setVendorAds] = useState([]);
    const [lastMessage, setLastMessage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [haveEvents, setHaveEvents] = useState(false);
    const [clickedAds, setClickedAds] = useState(-1);
    const _USER_DATA = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Juin & Juin
    /// </summary>
    useEffect(() => {
        if (!checkBrowser()) {
            _router.navigate(Pages._DEFAULT);
        }

        getLastMessage();
        getVendorAds();
        retrieveEvents();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const retrieveEvents = async () => {
        try {
            let responseJson = await ApiEngine.get(`${Routes._EVENT}`, {
                headers: { skipLoading: true }
            });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                let activeEvents = data?.data?.filter(
                    (x) => x.status === _EVENT_STATUS.ON_GOING
                );

                setHaveEvents(activeEvents?.length > 0);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getLastMessage = async () => {
        try {
            var csResponseJson = await ApiEngine.get(
                Routes._GET_CUSTOMER_SERVICE_INFO,
                { headers: { skipLoading: true } }
            );

            if (csResponseJson[ApiKey._API_SUCCESS_KEY]) {
                setCustomerSerivce(csResponseJson[ApiKey._API_DATA_KEY]);

                if (isFirstLoad) {
                    if (Math.floor(Math.random() * 10) % 2) {
                        setCustomerServiceAvatar(
                            require('../../../assets/images/chat/customerservice1.jpeg')
                        );
                    } else {
                        setCustomerServiceAvatar(
                            require('../../../assets/images/chat/customerservice2.jpeg')
                        );
                    }

                    setIsFirstLoad(false);
                }
            } else {
                throw csResponseJson[ApiKey._API_MESSAGE_KEY];
            }

            var messageResponseJson = await ApiEngine.get(
                Routes._GET_LAST_MESSAGE,
                { headers: { skipLoading: true } }
            );

            if (messageResponseJson[ApiKey._API_SUCCESS_KEY]) {
                setLastMessage(messageResponseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                throw messageResponseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            setIsLoading(false);
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getVendorAds = async () => {
        try {
            var responseJson = await ApiEngine.get(Routes._VENDOR_BANNERS, {
                headers: { skipLoading: true }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setVendorAds(responseJson[ApiKey._API_DATA_KEY]?.data);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            setIsLoading(false);
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const navigateToLiveChat = async () => {
        try {
            var responseJson = await ApiEngine.get(
                Routes._VERIFY_LIVECHAT_TOKEN
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let username = _USER_DATA.username;

            // TODO live chat webview
            _router.navigate(Pages._LIVE_CHAT, {
                name: _USER_DATA.username,
                email: _USER_DATA.username + '@epay4game.com',
                phone: username,
                department: customerService.departmentName,
                token: _USER_DATA.access_token
            });
        }
        catch (err) {
            const newWindow = window.open(`whatsapp://send?phone=${err}`, '_blank');
            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                alert('Pop-up blocked. Please allow pop-ups for this site.');
            }
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const readMessage = async (lastMessage) => {
        try {
            if (lastMessage && !lastMessage.read) {
                await ApiEngine.get(
                    Routes._READ_MESSAGE + '?msgid=' + lastMessage.id,
                    { headers: { skipLoading: true } }
                );

                getLastMessage();
            }
        } catch (err) {
            // do nothing
        }
    };

    if (!checkBrowser()) {
        return (
            <div className='body-content' style={{ marginTop: '10px' }}>
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text={t('LOADING')}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            zIndex: '1000001',
                            position: 'fixed',
                        })
                    }} />
                {
                    // TODO link to KOG
                    haveEvents &&
                    <></>
                }
                {
                    customerService &&
                    <>
                        <Button variant={'link'} w={'full'} onClick={navigateToLiveChat}>
                            <Flex w={'full'} align={'center'}>
                                <Box p={0.5} borderRadius={50} borderWidth={1} borderColor={'white'}>
                                    <Image
                                        boxSize={'45px'}
                                        objectFit={'cover'}
                                        borderRadius={50}
                                        src={customerServiceAvatar} />
                                </Box>
                                <Stack spacing={0} ml={3}>
                                    <Text textAlign={'left'} fontSize={'sm'}>{t('CUSTOMER_SERVICE')}</Text>
                                    <Text textAlign={'left'} color={'#e4b6ff'} fontStyle={'italic'}>{t('WELCOME_TO_EPAY')}</Text>
                                </Stack>
                                <Spacer />
                                <Text>{t('NOW')}</Text>
                            </Flex>
                        </Button>
                        <Divider className="divider-horizontal-dark" />
                    </>
                }
                {
                    !stringIsNullOrEmpty(customerService?.telegramLink) &&
                    <>
                        <Button variant={'link'} w={'full'} onClick={() => {
                            const newWindow = window.open(customerService['telegramLink'], '_blank');
                            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                                alert('Pop-up blocked. Please allow pop-ups for this site.');
                            }
                        }}>
                            <Flex w={'full'} align={'center'}>
                                <Box p={0.5} borderRadius={50} borderWidth={1} borderColor={'white'}>
                                    <Image
                                        boxSize={'45px'}
                                        objectFit={'cover'}
                                        borderRadius={50}
                                        src={require('../../../assets/images/telegram.png')} />
                                </Box>
                                <Stack spacing={0} ml={3}>
                                    <Text textAlign={'left'} fontSize={'sm'}>{t('TELEGRAM')}</Text>
                                    <Text textAlign={'left'} color={'#e4b6ff'} fontStyle={'italic'}>{t('NEED_TO_TALK_WE_RE_LISTENING')}</Text>
                                </Stack>
                                <Spacer />
                                <Text>{t('NOW')}</Text>
                            </Flex>
                        </Button>
                        <Divider className="divider-horizontal-dark" />
                    </>
                }
                {
                    lastMessage &&
                    <>
                        <Button variant={'link'} w={'full'} onClick={() => {
                            readMessage(lastMessage);
                            _router.navigate(Pages._ROBOT_MESSAGING);
                        }}>
                            <Flex w={'full'} align={'center'}>
                                <Image
                                    boxSize={'50px'}
                                    objectFit={'cover'}
                                    borderRadius={50}
                                    src={require('../../../assets/images/chat/chat-robot.png')} />
                                <Stack spacing={0} ml={3}>
                                    <Text textAlign={'left'} fontSize={'sm'}>{t('ANNOUNCEMENT')}</Text>
                                    <Text textAlign={'left'} color={'#e4b6ff'} fontStyle={'italic'} noOfLines={0} maxW={'48vw'}>{lastMessage?.message}</Text>
                                </Stack>
                                <Spacer />
                                <Text>{moment(lastMessage.creation_date).isBefore(moment(), 'day')
                                    ? moment(
                                        lastMessage.creation_date
                                    ).format('DD MMM YYYY')
                                    : moment(
                                        lastMessage.creation_date
                                    ).format('hh:mm A')}
                                </Text>
                            </Flex>
                        </Button>
                        <Divider className="divider-horizontal-dark" />
                    </>
                }
                {
                    vendorAds.length > 0 &&
                    <div className="bottom-sticky-button" style={{ bottom: 30 }}>
                        <Carousel
                            autoPlay
                            infiniteLoop
                            interval={5000}
                            onClickItem={(item) => console.log(item)}
                        >
                            {
                                vendorAds.map((item, index) => {
                                    return (
                                        <div style={{ position: 'relative' }} onClick={() => {
                                            if (index == clickedAds) {
                                                setClickedAds(-1)
                                            }
                                            else {
                                                setClickedAds(index)
                                            }
                                        }}>
                                            <Image
                                                opacity={index == clickedAds ? 0.5 : 1}
                                                boxSize={'180px'}
                                                fit={'fill'}
                                                src={item?.image} />
                                            {
                                                index == clickedAds &&
                                                <AbsoluteCenter w={!stringIsNullOrEmpty(item?.cta) ? '30vw' : ''}>
                                                    <Flex>
                                                        {
                                                            !stringIsNullOrEmpty(item?.cta) &&
                                                            <>
                                                                <Stack>
                                                                    <IconButton
                                                                        borderRadius={'50%'}
                                                                        width={'10vw'}
                                                                        backgroundColor={'white'}
                                                                        icon={<Image boxSize={'35px'} fit={'contain'} src={require('../../../assets/images/domain.png')} />}
                                                                        onClick={() => {
                                                                            const newWindow = window.open(item.cta, '_blank');
                                                                            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                                                                                alert('Pop-up blocked. Please allow pop-ups for this site.');
                                                                            }

                                                                            setClickedAds(-1);
                                                                        }}
                                                                    />
                                                                    <Text>{t('BROWSE')}</Text>
                                                                </Stack>
                                                                <Spacer />
                                                            </>
                                                        }
                                                        <Stack>
                                                            <IconButton
                                                                borderRadius={'50%'}
                                                                width={'10vw'}
                                                                backgroundColor={'white'}
                                                                icon={<Image boxSize={'35px'} fit={'contain'} src={require('../../../assets/images/share.png')} />}
                                                                onClick={async () => {
                                                                    if (!stringIsNullOrEmpty(item.image)) {
                                                                        let blob = await fetch(item.image).then(r => r.blob());
                                                                        let dataUrl = await new Promise(resolve => {
                                                                            let reader = new FileReader();
                                                                            reader.onload = () => resolve(reader.result);
                                                                            reader.readAsDataURL(blob);
                                                                        });

                                                                        share(item.title, dataUrl);
                                                                        setClickedAds(-1);
                                                                    }
                                                                }}
                                                            />
                                                            <Text>{t('SHARE')}</Text>
                                                        </Stack>
                                                    </Flex>
                                                </AbsoluteCenter>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "50rem" }}>
                        <Center>
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'}>{t('CHAT')}</Text>
                        </Center>
                        <div className='body-content' style={{ marginTop: '2rem' }}>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text={t('LOADING')}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        zIndex: '1000001',
                                        position: 'fixed',
                                    })
                                }} />
                            {
                                // TODO link to KOG
                                haveEvents &&
                                <></>
                            }
                            {
                                customerService &&
                                <>
                                    <Button variant={'link'} w={'full'} bg={'linear-gradient(#ac4cfe,#7416ab)'} mb={6} padding={4} pr={8} borderRadius={16} onClick={navigateToLiveChat}>
                                        <Flex w={'full'} align={'center'}>
                                            <Box p={0.5} borderRadius={50} borderWidth={1} borderColor={'white'}>
                                                <Image
                                                    boxSize={'45px'}
                                                    objectFit={'cover'}
                                                    borderRadius={50}
                                                    src={customerServiceAvatar} />
                                            </Box>
                                            <Stack spacing={0} ml={3}>
                                                <Text textAlign={'left'}>{t('CUSTOMER_SERVICE')}</Text>
                                                <Text textAlign={'left'} color={'#f8c763'} fontStyle={'italic'}>{t('WELCOME_TO_EPAY')}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Text>{t('NOW')}</Text>
                                        </Flex>
                                    </Button>
                                </>
                            }
                            {
                                !stringIsNullOrEmpty(customerService?.telegramLink) &&
                                <>
                                    <Button variant={'link'} w={'full'} bg={'linear-gradient(#ac4cfe,#7416ab)'} padding={4} mb={6} pr={8} borderRadius={16} onClick={() => {
                                        const newWindow = window.open(customerService['telegramLink'], '_blank');
                                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                                            alert('Pop-up blocked. Please allow pop-ups for this site.');
                                        }
                                    }}>
                                        <Flex w={'full'} align={'center'}>
                                            <Box p={0.5} borderRadius={50} borderWidth={1} borderColor={'white'}>
                                                <Image
                                                    boxSize={'45px'}
                                                    objectFit={'cover'}
                                                    borderRadius={50}
                                                    src={require('../../../assets/images/telegram.png')} />
                                            </Box>
                                            <Stack spacing={0} ml={3}>
                                                <Text textAlign={'left'}>{t('TELEGRAM')}</Text>
                                                <Text textAlign={'left'} color={'#f8c763'} fontStyle={'italic'}>{t('NEED_TO_TALK_WE_RE_LISTENING')}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Text>{t('NOW')}</Text>
                                        </Flex>
                                    </Button>
                                </>
                            }
                            {
                                lastMessage &&
                                <>
                                    <Button variant={'link'} w={'full'} bg={'linear-gradient(#ac4cfe,#7416ab)'} padding={4} pr={8} borderRadius={16} onClick={() => {
                                        readMessage(lastMessage);
                                        _router.navigate(Pages._ROBOT_MESSAGING);
                                    }}>
                                        <Flex w={'full'} align={'center'}>
                                            <Image
                                                boxSize={'50px'}
                                                objectFit={'cover'}
                                                borderRadius={50}
                                                src={require('../../../assets/images/chat/chat-robot.png')} />
                                            <Stack spacing={0} ml={3} w={'32rem'}>
                                                <Text textAlign={'left'}>{t('ANNOUNCEMENT')}</Text>
                                                <Text textAlign={'left'} color={'#f8c763'} fontStyle={'italic'} minW={4} noOfLines={0}>{lastMessage?.message}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Text ml={5}>{moment(lastMessage.creation_date).isBefore(moment(), 'day')
                                                ? moment(
                                                    lastMessage.creation_date
                                                ).format('DD MMM YYYY')
                                                : moment(
                                                    lastMessage.creation_date
                                                ).format('hh:mm A')}
                                            </Text>
                                        </Flex>
                                    </Button>
                                </>
                            }
                            {
                                vendorAds.length > 0 &&
                                <div style={{ marginTop: '3rem', marginBottom: '-3rem' }}>
                                    <Carousel
                                        autoPlay
                                        infiniteLoop
                                        interval={5000}
                                        onClickItem={(item) => console.log(item)}
                                    >
                                        {
                                            vendorAds.map((item, index) => {
                                                return (
                                                    <div style={{ position: 'relative' }} onClick={() => {
                                                        if (index == clickedAds) {
                                                            setClickedAds(-1)
                                                        }
                                                        else {
                                                            setClickedAds(index)
                                                        }
                                                    }}>
                                                        <Image
                                                            opacity={index == clickedAds ? 0.5 : 1}
                                                            fit={'fill'}
                                                            src={item?.image} />
                                                        {
                                                            index == clickedAds &&
                                                            <AbsoluteCenter w={!stringIsNullOrEmpty(item?.cta) ? '30vw' : ''}>
                                                                <Flex>
                                                                    {
                                                                        !stringIsNullOrEmpty(item?.cta) &&
                                                                        <>
                                                                            <Stack>
                                                                                <IconButton
                                                                                    borderRadius={'50%'}
                                                                                    width={'10vw'}
                                                                                    backgroundColor={'white'}
                                                                                    icon={<Image boxSize={'35px'} fit={'contain'} src={require('../../../assets/images/domain.png')} />}
                                                                                    onClick={() => {
                                                                                        const newWindow = window.open(item.cta, '_blank');
                                                                                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                                                                                            alert('Pop-up blocked. Please allow pop-ups for this site.');
                                                                                        }

                                                                                        setClickedAds(-1);
                                                                                    }}
                                                                                />
                                                                                <Text>{t('BROWSE')}</Text>
                                                                            </Stack>
                                                                            <Spacer />
                                                                        </>
                                                                    }
                                                                    <Stack>
                                                                        <IconButton
                                                                            borderRadius={'50%'}
                                                                            width={'10vw'}
                                                                            backgroundColor={'white'}
                                                                            icon={<Image boxSize={'35px'} fit={'contain'} src={require('../../../assets/images/share.png')} />}
                                                                            onClick={async () => {
                                                                                if (!stringIsNullOrEmpty(item.image)) {
                                                                                    let blob = await fetch(item.image).then(r => r.blob());
                                                                                    let dataUrl = await new Promise(resolve => {
                                                                                        let reader = new FileReader();
                                                                                        reader.onload = () => resolve(reader.result);
                                                                                        reader.readAsDataURL(blob);
                                                                                    });

                                                                                    share(item.title, dataUrl);
                                                                                    setClickedAds(-1);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <Text>{t('SHARE')}</Text>
                                                                    </Stack>
                                                                </Flex>
                                                            </AbsoluteCenter>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>
                            }
                        </div>
                    </div>
                </Center>
            </>
        )
    }
}