import React, { useEffect, useState, createRef } from "react";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Center,
    Input,
    InputGroup,
    InputRightElement,
    CardBody,
    Card
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { disposeCustomDialog, showCustomDialog } from "../../../application/action/app_action";
import { copyToClipboard, share, dataURLtoFile, checkBrowser, numberWithCurrencyFormat, stringIsNullOrEmpty, createFormBody, isObjectEmpty } from "../../../common/util";
import GeneralTopBar from "../../../component/general-top-bar";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { ApiKey, TransactionTypeId, TransactionRequestStatus, FirebaseKey, FirebaseActionType } from "../../../common/constant";
import QrCode from "../../../component/qr-code";
import { useScreenshot, createFileName } from "use-react-screenshot";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import QRCode from "react-qr-code";
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import PasswordModal from "../../../component/password-modal";
// import database from 'firebase';

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { username, alias } = useSelector(state => state.authState.userData);
    const [referralCode, setReferralCode] = useState('-');
    const [referralUrl, setReferralUrl] = useState('-');
    const { isMemberReferral } = _location?.state;
    const _BOTTOM_ELEMENTS = [
        { title: 'SAVE_QR', borderTopLeft: true },
        { title: 'SHARE_QR', borderTopRight: true }
    ];
    const ref = createRef(null);
    const { title, requestCode, amount } = _location?.state;
    const [reqCode, setReqCode] = useState(requestCode);
    const [isExpired, setIsExpired] = useState(false);
    const [expiredTime, setExpiredTime] = useState();
    const [data, setData] = useState({});
    const [isGenerateAgain, setIsGenerateAgain] = useState(false);
    const [firstInit, setFirstInit] = useState(true);
    const [invalidPass, setInvalidPass] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [authorizationModal, setAuthorizationModal] = useState({
        signature: null,
        password: '',
        visible: false,
        tac: '',
    });

    /// <summary>
    /// Author: Juin
    ///</summary>
    useEffect(() => {
        (async () => {
            await retrieveRequest(reqCode);
        })();
    }, [])

    /// <summary>
    /// Author: Juin
    ///</summary>
    // useEffect(() => {
    //     let firebaseResponse = database()
    //         .ref(FirebaseKey._TRANSACTION_REQUEST + userData?.guid)
    //         .on('value', async (request) => {
    //             let data = request.val();

    //             if (data === null) {
    //                 await retrieveRequest(reqCode);
    //             }

    //             if (data != null) {
    //                 if (data?.action === FirebaseActionType._PASSWORD_REQUEST) {
    //                     // cancel the new code transaction
    //                     setReqCode(data["code"])

    //                     // retrieve provider data
    //                     retrieveRequest(data["code"]);
    //                     setAuthorizationModal({ ...authorizationModal, visible: true, tac: data?.tac });
    //                 }
    //                 else if (data?.action === FirebaseActionType._PENDING) {
    //                     if (!firstInit) {
    //                         setReqCode(data?.code)
    //                     }
    //                 }
    //             }
    //         });

    //     return () => {
    //         database()
    //             .ref(FirebaseKey._TRANSACTION_REQUEST + userData?.guid)
    //             .off('value', firebaseResponse);
    //     }
    // }, [reqCode])

    /// <summary>
    /// Author: Javacrypt
    /// Edited: Juin
    /// </summary>
    useEffect(() => {
        setAuthorizationModal({ ...authorizationModal, visible: data["status"] == TransactionRequestStatus._REQUEST_PENDING_PASSWORD_CONFIRMATION ? true : false });

        if (!isObjectEmpty(data) && !stringIsNullOrEmpty(data["status"]) && data["status"] != TransactionRequestStatus._PENDING && data["status"] != TransactionRequestStatus._REQUEST_PENDING_PASSWORD_CONFIRMATION) {
            if (!invalidPass) {
                // _navigate(Pages.Re, {
                //     title: 'WITHDRAW_CREDIT',
                //     amount: data.amount,
                //     requestCode: responseJson['data']
                // });

                // navigation.navigate({
                //     name: Navigators._RESULT_SCREEN, key: Navigators._RESULT_SCREEN,
                //     params: content(data["transactionTypeId"], data["status"])
                // })
            } else {
                _dispatch(showCustomDialog({
                    success: false,
                    content: errMsg,
                    disableHardwareBackPress: true,
                    onConfirm: () => {
                        _dispatch(disposeCustomDialog());
                        // navigation.navigate({
                        //     name: Navigators._RESULT_SCREEN, key: Navigators._RESULT_SCREEN,
                        //     params: content(data["transactionTypeId"], data["status"])
                        // });
                    }
                }))
            }
        }
    }, [data])

    /// <summary>
    /// Author: Juin
    ///</summary>
    useEffect(() => {
        if (isExpired) {
            setExpiredTime(0);
            setIsGenerateAgain(false);
        }
    }, [isExpired])

    /// <summary>
    /// Author: Juin
    ///</summary>
    useEffect(() => {
        if (isGenerateAgain) {
            retrieveRequest(reqCode);
        }
    }, [isGenerateAgain])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const cancelHandler = () => {
        _dispatch(showCustomDialog({
            title: t("INCOMPLETE_TRANSACTION"),
            content: t("ARE_YOU_SURE_YOU_WANT_TO_LEAVE_YOU_WILL_LOSE_YOUR_DATA_IF_YOU_CONTINUE"),
            confirmTxt: t("CONFIRM"),
            onConfirm: () => {
                _navigate(-1);
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
        return true;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const amountPrefix = (dataAmount) => {
        let amountStr = "";

        if (dataAmount < 0) {
            amountStr = `- RM ${numberWithCurrencyFormat(Math.abs(dataAmount))}`;
        }
        else {
            amountStr = `+ RM ${numberWithCurrencyFormat(dataAmount)}`;
        }

        return amountStr;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const content = (transactionTypeId, status) => {
        let checkAmount = data.amount > 0;

        let details = stringIsNullOrEmpty(data["vendor"]) && stringIsNullOrEmpty(data["playerUsername"]) ? (
            [
                { label: 'TRANSACTION_NO', value: data?.code },
                { label: 'DATE_AND_TIME', value: moment(data?.createdTime).format("DD MMM YYYY, hh:mm A") }
            ]
        ) : (
            [
                { label: 'TRANSACTION_NO', value: data?.code },
                { label: 'DATE_AND_TIME', value: moment(data?.createdTime).format("DD MMM YYYY, hh:mm A") },
                { label: 'VENDOR', value: !stringIsNullOrEmpty(data["playerVendorGameName"]) ? data["playerVendorGameName"] : data["playerVendor"] },
                { label: 'PLAYER_ID', value: !stringIsNullOrEmpty(data["playerUsername"]) ? data["playerUsername"] : "" }
            ]
        )

        let tempObj = {
            isReceipt: true,
            requiredBackToMain: true,
            amount: amountPrefix(data.amount),
            details: details
        };

        switch (transactionTypeId) {
            case TransactionTypeId._WALLET_TRANSFER:
                tempObj = {
                    ...tempObj,
                    headerTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        (checkAmount ? "QR_CODE_DEPOSIT_SUCCESS" : "WITHDRAW_CREDIT_SUCCESS") :
                        (checkAmount ? "QR_CODE_DEPOSIT_REJECTED" : "WITHDRAW_CREDIT_REJECTED"),
                    contentTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        (checkAmount ? "QR_CODE_DEPOSIT_SUCCESS" : "WITHDRAW_CREDIT_SUCCESS") :
                        (checkAmount ? "QR_CODE_DEPOSIT_REJECTED" : "WITHDRAW_CREDIT_REJECTED"),
                    contentImage: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        require('../../../assets/images/graphic-success-mobile.png') :
                        require('../../../assets/images/graphic-failed-mobile.png'),
                }
                break;
            case TransactionTypeId._SET_SCORE:
                tempObj = {
                    ...tempObj,
                    headerTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        (checkAmount ? "QR_CODE_EPOINT_SUCCESS" : "WITHDRAW_EPOINT_SUCCESS") :
                        (checkAmount ? "QR_CODE_EPOINT_REJECTED" : "WITHDRAW_EPOINT_REJECTED"),
                    contentTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        (checkAmount ? "QR_CODE_EPOINT_SUCCESS" : "WITHDRAW_EPOINT_SUCCESS") :
                        (checkAmount ? "QR_CODE_EPOINT_REJECTED" : "WITHDRAW_EPOINT_REJECTED"),
                    contentImage: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        require('../../../assets/images/graphic-success-mobile.png') :
                        require('../../../assets/images/graphic-failed-mobile.png'),
                }
                break;
            case TransactionTypeId._REWARD_CLAIM:
                tempObj = {
                    ...tempObj,
                    headerTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        ("WITHDRAW_REWARD_SUCCESS") :
                        ("WITHDRAW_REWARD_REJECTED"),
                    contentTitle: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        ("WITHDRAW_REWARD_SUCCESS") :
                        ("WITHDRAW_REWARD_REJECTED"),
                    contentImage: status == TransactionRequestStatus._REQUEST_SUCCESS ?
                        require('../../../assets/images/graphic-success-mobile.png') :
                        require('../../../assets/images/graphic-failed-mobile.png'),
                }
                break;
            default:
                break;
        }

        return tempObj;
    }

    /// <summary>
    /// Author: Juin
    ///</summary>
    const retrieveRequest = async (code) => {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_TRANSACTION_REQUEST}?code=${code}`);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY]
                setData(data);
                setIsExpired(stringIsNullOrEmpty(data['expiredTime']));

                if (!stringIsNullOrEmpty(data["expiredTime"])) {
                    // setExpiredTime(Math.max(0, moment(data["expiredTime"]).diff(moment(), 'seconds')));
                    setExpiredTime(moment(data['expiredTime']).valueOf());
                }
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        setFirstInit(false);
    }

    /// <summary>
    /// Author: Juin
    ///</summary>
    const generateAgain = async () => {
        try {
            let url = `${Routes._CREATE_TRANSACTION_REQUEST}?amount=${data["amount"]}`;

            if (!stringIsNullOrEmpty(data["playerId"])) {
                url += `&playerId=${data["playerId"]}`
            }

            let responseJson = await ApiEngine.post(url);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setReqCode(responseJson[ApiKey._API_DATA_KEY]);
                setIsGenerateAgain(true);
            }
            else {
                _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
            }

        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    ///</summary>
    const submitWithdrawal = async (status, authorization) => {
        try {
            let params = {
                'Code': reqCode,
                'Status': (status ? TransactionRequestStatus._REQUEST_SUCCESS : TransactionRequestStatus._REQUEST_REJECTED),
                'Password' : authorization.password
            }

            let formBody = createFormBody(params);
            let responseJson = await ApiEngine.post(Routes._SETTLE_TRANSACTION_REQUEST, formBody);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                setInvalidPass(true);
                setErrMsg(responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }))
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const TimerRenderer = ({ minutes, seconds }) => {
        return <Center><span className="app-countdown-timer"><Text fontSize={'md'}>{zeroPad(minutes)} : {zeroPad(seconds)}</Text></span></Center>;
    };

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={title} bgColor="linear-gradient(#a94ff1,#9e32dd)" onBack={cancelHandler} />
                <Center mt={6}>
                    <Stack spacing={0}>
                        <Text textAlign={'center'} fontSize={'sm'}>{t('WITHDRAW_AMOUNT')}</Text>
                        <Text textAlign={'center'} fontSize={'3xl'} fontWeight={700} color={'#ff5656'} mb={3}>RM {numberWithCurrencyFormat(amount)}</Text>
                        <Card borderRadius={20} paddingInline={3} bg={'unset'}>
                            <CardBody borderTopRadius={20} bg={'linear-gradient(#a649ec,#7918af)'}>
                                <Text textAlign={'center'} fontSize={'md'}>{`${t('WITHDRAW_FROM_ID')} : ${!stringIsNullOrEmpty(data["playerUsername"]) ? data["playerUsername"] : username}`}</Text>
                            </CardBody>
                            <CardBody borderBottomRadius={20} bg={'linear-gradient(#a951f2,#7416a9)'}>
                                <Stack>
                                    <Center>
                                    <Box className="my-qr-code-box" mb={3} mt={3} height={'14.5rem'} width={'14.5rem'}>
                                        <Center>
                                            <QrCode
                                                value={reqCode}
                                                qrSize={180}
                                                containerSize={210}
                                                borderRadius={20} />
                                        </Center>
                                    </Box>
                                    </Center>
                                    {expiredTime && 
                                    <Countdown
                                        date={expiredTime}
                                        renderer={TimerRenderer}
                                        onComplete={() => { setIsExpired(true) }}
                                    />}
                                </Stack>
                            </CardBody>
                        </Card>
                        <Text fontSize={'sm'} mt={3} textAlign={'center'} color={'#d0a5eb'} paddingInline={3}>{isExpired ? t("TRANSACTION_TIMEOUT_PLEASE_GENERATE_NEW_QR_CODE") :
                            t("PLEASE_SHOW_QR_TO_SHOP")}</Text>
                        {isExpired && <Center mt={3}>
                            <Button className="grey-gradient-button" w={'fit-content !important'} paddingInline={16}
                            onClick={()=>{generateAgain()}}>{t('GENERATE_AGAIN')}</Button>
                        </Center>}
                    </Stack>
                </Center>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            submitWithdrawal(isDone, authorization)
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "30rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => cancelHandler()}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(title)}</Text>
                        </div>
                        <Stack spacing={0}>
                        <Text textAlign={'center'} fontSize={'sm'}>{t('WITHDRAW_AMOUNT')}</Text>
                        <Text textAlign={'center'} fontSize={'3xl'} fontWeight={700} color={'#ff5656'} mb={3}>RM {numberWithCurrencyFormat(amount)}</Text>
                        <Card borderRadius={20} paddingInline={3} bg={'unset'}>
                            <CardBody borderTopRadius={20} bg={'linear-gradient(#a649ec,#7918af)'}>
                                <Text textAlign={'center'} fontSize={'md'}>{`${t('WITHDRAW_FROM_ID')} : ${!stringIsNullOrEmpty(data["playerUsername"]) ? data["playerUsername"] : username}`}</Text>
                            </CardBody>
                            <CardBody borderBottomRadius={20} bg={'linear-gradient(#a951f2,#7416a9)'}>
                                <Stack>
                                    <Center>
                                    <Box className="my-qr-code-box" mb={3} mt={3} height={'14.5rem'} width={'14.5rem'}>
                                        <Center>
                                            <QrCode
                                                value={reqCode}
                                                qrSize={180}
                                                containerSize={210}
                                                borderRadius={20} />
                                        </Center>
                                    </Box>
                                    </Center>
                                    {expiredTime && 
                                    <Countdown
                                        date={expiredTime}
                                        renderer={TimerRenderer}
                                        onComplete={() => { setIsExpired(true) }}
                                    />}
                                </Stack>
                            </CardBody>
                        </Card>
                        <Text fontSize={'sm'} mt={3} textAlign={'center'} color={'#d0a5eb'} paddingInline={3}>{isExpired ? t("TRANSACTION_TIMEOUT_PLEASE_GENERATE_NEW_QR_CODE") :
                            t("PLEASE_SHOW_QR_TO_SHOP")}</Text>
                        {isExpired && 
                        <Center mt={3}>
                            <Button mt={2} className="grey-gradient-button" bg={'linear-gradient(#e5b823, #8d7216) !important'} border={'0 !important'} w={'fit-content !important'} paddingInline={16}
                            onClick={()=>{generateAgain()}}>{t('GENERATE_AGAIN')}</Button>
                        </Center>}
                    </Stack>
                    </div>
                    <PasswordModal
                        authorization={authorizationModal}
                        onPasswordHandler={(isDone = false, authorization) => {
                            if (isDone) {
                                submitWithdrawal(isDone, authorization)
                            }

                            setAuthorizationModal({ ...authorization, visible: false });
                        }} />
                </Center>
                <Footer />
            </>
        )
    }
}